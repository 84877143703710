<!--非首页-->
<template>
  <el-container style="height: 100%">
    <el-header class="header" style="height:48px;padding:0">
      <Header />
    </el-header>
    <el-container>
      <el-main :key="$route.path" style="padding:0;background:#E9EFF6">
        <slot></slot>
      </el-main>
    </el-container>
    <Footer />
  </el-container>
</template>
<script>
import Header from "./Header";
import Main from "./Main";
import Footer from "./Footer";
import { getCookie,setCookie,removeCookie,getLoginUUId,getLoginUserId,getLoginRealName,getLoginUserName,getLoginUserUrl } from "../plugins/cookie";
export default {
  name: "app",
  created() {
    console.log(this.$route);
    console.log(this.$route.params.context);
    console.log(window.location);
    this.getSchoolEntity();
  },
  components: {
    Header,
    Main,
    Footer,
  },
  methods: {
    async getSchoolEntity() {
      let school = await this.$get(`/admin/getSchoolByContext/${this.$route.params.context}`);
      document.title = school.schoolName;
      return school;
    },
  },
};
</script>
