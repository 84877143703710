<!--非首页-->
<template>
  <div>
    <div class="nav" :style="{ backgroundColor: themeColor }">
      <div
        style="
          width: 1200px;
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <div>
          <div class="logo"><img :src="webInfo.logoAttachmentUrl" /></div>
          <span class="font">{{ webInfo.name }}</span>
        </div>
        <div style="width: auto; text-algin: right">
          <a v-if="$route.params.context === 'hepec'" href="//hepeckcsz.zhihuishu.com" target="_blank"><span class="kcsz-btn">课程思政平台</span></a>
          <el-dropdown v-if="userInfo" @command="logout">
            <span
              class="el-dropdown-link"
              style="color: rgba(255, 255, 255, 0.85)"
            >
              <el-button type="primary" class="reg-login">
                {{ userInfo }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-if="['admin', 'teacher'].includes(role)"
                command="system"
                >管理后台</el-dropdown-item
              >
              <el-dropdown-item command="toZhihuishu"
                >在线学堂</el-dropdown-item
              >
              <el-dropdown-item command="logout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button v-else class="reg-login" @click="toLogin"
            >登录/注册</el-button
          >
          <el-button
            class="apply-file"
            v-if="quick.enabled === 'yes'"
            style="margin-left: 10px"
            :style="quick.style"
            @click="windowOpen(quick.url)"
            >{{ quick.text }}</el-button
          >
        </div>
      </div>
    </div>
    <div class="nav-wrapper">
      <div style="width: 1200px; margin: 0 auto">
        <router-link v-for="({ path, name }, i) in tabs" :to="path" :key="i"
          ><span class="nav-item" :class="{ active: currentPath === path }">{{
            name
          }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getCookie,
  getLoginRealName,
  getLoginUserId,
  removeCookie,
  getLoginUUId,
} from "../plugins/cookie";
import axios from "axios";
export default {
  inject: ["reload"],
  computed: {
    themeColor() {
      return this.$store.state.themeColor;
    },
    userInfo: function () {
      if (getCookie("CASLOGC")) {
        return getLoginRealName();
      }
      return "";
    },
    userId() {
      return getLoginUserId();
    },
    tabs() {
      let ret = [];
      // console.log(this.$store.state.tabs)
      this.showModules.forEach((ele) => {
        this.$store.state.tabs.forEach((element) => {
          if (element.menuId === ele.menuId) {
            let e = element;
            e.name = ele.menuName;
            if (!e.path.startsWith("/" + this.$route.params.context)) {
              e.path = "/" + this.$route.params.context + e.path;
            }
            ret.push(e);
          }
        });
      });
      return ret;
    },
    currentPath() {
      return this.$route.path;
    },
  },
  data() {
    return {
      webInfo: {
        name: "",
        logoAttachmentUrl: "",
      },
      isAdmin: false,
      showModules: [],
      quick: {},
      role: null,
    };
  },
  created() {
    this.getWebInfo();
    this.initQuick();
      this.initModule();
    if(this.userId){
      this.getRoleInfo();
    }
  },
  methods: {
    windowOpen(url) {
      if (!url.startsWith("http")) {
        url = "http://" + url;
      }
      window.open(url);
    },
    async getSchoolEntity() {
      return await this.$get(
        `/admin/getSchoolByContext/${this.$route.params.context}`
      );
    },
    async initQuick() {
      let res = await this.$get("/school-quick/findList/foreign", {
        foreign: "schoolId",
        value: (await this.getSchoolEntity()).id,
      });
      this.quick = res[0] || {};
      if (this.quick.color)
        this.quick.style = "backgroundColor:" + this.quick.color;
    },
    async initModule() {
      let url =
        "https://aidedteachingdata.zhihuishu.com/aidedteachingData/firstUndergraduate/getPagePermission?schoolId=" +
        (await this.getWebInfo()).zhihuishuId;
      let res = await axios.get(url);
      this.showModules = res.data.rt.sort((a,b)=>a.sort-b.sort);
    },
    async getRoleInfo() {
      const roleInfo = await this.$get("/admin/judgeRole");
      this.role = roleInfo;
    },
    // async initIsAdmin() {
    //   let url =
    //     "https://aidedteachingdata.zhihuishu.com/aidedteachingData/firstUndergraduate/getMenuPermission?schoolId=" +
    //     (await this.getWebInfo()).zhihuishuId +
    //     "&&uuid=" +
    //     getLoginUUId();
    //   let res = await axios.get(url);
    //   this.isAdmin =
    //     res.data.rt &&
    //     res.data.rt.fuMenuDtos &&
    //     res.data.rt.fuMenuDtos.length > 0;
    // },
    toLogin() {
      let url = window.location.href;
      url = url.replace("#", "%23");
      window.location.href =
        "https://passport.zhihuishu.com/login?source=3&service=" + url;
    },
    async getWebInfo() {
      if (this.webInfo.id) {
        return this.webInfo;
      }
      this.webInfo = await this.$get(
        `/admin/getSchoolByContext/${this.$route.params.context}`
      );
      this.webInfo.color &&
        this.$store.commit("mergeToState", { themeColor: this.webInfo.color });
      return this.webInfo;
    },
    async logout(command) {
      switch (command) {
        case "system":
          window.open(
            `https://zygl.zhihuishu.com/#/${this.$route.params.context}/welcome`
          );
          break;
        case "toZhihuishu":
          window.open(
            "https://onlineh5.zhihuishu.com/onlineWeb.html#/teachIndex"
          );
          break;
        case "logout":
          removeCookie("CASLOGC", ".zhihuishu.com");
          removeCookie("CASTGC", ".zhihuishu.com");
          this.$router.push("/" + this.$route.params.context);
      }
    },
  },
};
</script>
<style scoped>
.logo {
  width: 210px;
  margin: 0 20px 0 0;
  display: inline-block;
  vertical-align: middle;
}
.logo img {
  width: 100%;
}
.font {
  font-size: 22px;
  color: #fff;
}
.reg-login {
  background: #ffffff;
  border-radius: 20px;
  color: #5676dc;
  border: 0;
  padding: 10px 24px;
  font-weight: 600;
  cursor: pointer;
  border: solid 1px #fff;
  background-color: transparent;
  color: #fff;
}
.apply-file {
  background: #20ce80;
  border-radius: 20px;
  color: #fff;
  border: 0;
  padding: 10px 24px;
  font-weight: 600;
  cursor: pointer;
}
.nav {
  /* height: 95px; */
  padding: 10px;
  background-color: #5676dc;
  position: relative;
}
.nav-item {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  margin-right: 36px;
  color: rgba(255, 255, 255, 0.85);
  line-height: 47px;
  padding: 0;
}
.nav-item.active {
  border-bottom: rgba(255, 255, 255, 0.85) solid 1px;
}
.nav-wrapper {
  /* padding-left: 80px; */
  background-color: rgba(0, 0, 0, 0.5);
  position: relative;
  top: 0;
}
.kcsz-btn{
  cursor: pointer;
  display: inline-block;
  background-image: linear-gradient(140deg, #FFC652 0%, #E88F02 100%);
  font-size: 16px;
  line-height: 40px;
  padding: 0 16px;
  color: #fff;
  border-radius: 20px;
  border:0;
  margin-right: 16px;
}
</style>
