<template>
  <div class="wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item
        v-for="({name,path},i) in breadCrumb"
        :key="i"
        :to="(!path || i === breadCrumb.length-1 || breadCrumb.length === 1) ? undefined:{ path }"
      >{{name}}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="main-container">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    // console.log(this.$store.state.breadCrumb);
  },
  computed: {
    breadCrumb: function () {
      return this.$store.state.breadCrumb;
    },
  },
};
</script>
<style scoped>
.wrapper{height:calc(100% - 66px);}
.main-container {
  height:100%;
  background: #fff;
  margin-top: 8px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  border-radius:3px;
  padding:20px;
}
</style>