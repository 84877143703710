<template>
  <div class="index-foot">
    <div class="links-title">
      · 友情链接 ·
    </div>
    <div class="links-list">
      <a class="link" v-for="(item,index) in linksList" :key="index" :href="item.url" target="blank">{{item.text}}</a>
    </div>
    <div class="copy-right">
      版权归上海卓越睿新技术有限公司所有 沪ICP证10007183
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    userInfo: function () {
      return this.$store.state.userInfo;
    },
  },
  data() {
    return {
      linksList:[],
    }
  },
  created() {
    this.getLinks();
  },
  methods: {
    async getLinks() {
      const obj = await this.$get("/blogroll/findPage",{
        pageSize:10,
        pageNum: 1,
        sortType:'desc'
      });
      this.linksList = obj.content;
    },
  }
};
</script>    
<style scoped>
.index-foot{background-color: #373737;
  text-align: center;
  padding: 30px 0 20px 0;
}
.index-foot .links-title{font-size: 16px; color: #ececec; margin-bottom: 36px}
.index-foot .links-list{margin: 36px 0 47px 0}


.index-foot .link {
  display: inline-block;
  padding: 0 20px;
  color: rgb(157, 157, 157);
}
.index-foot .link:not(:last-child) {
  border-right: solid 1px #9d9d9d;
}
.index-foot .link a{color: rgb(157, 157, 157);}

.index-foot .copy-right{color: #9b9b9b; font-size: 12px}
</style>